import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import i18next from 'i18next'
import { generateNewVPNKeys, createSession, sendInputData, sendSessionOption, sendKeyContainer, createDS,
         getDSResult, sendTokenPath, deleteSession } from '../actions/api';
import { setSecret, setVPNTokenData, clearVPNTokenData, setVPNToken, setProfileFields, clearProfileFields, setResultKey, setErrorResponse, setGlobalProcessing, setAllFieldsFiled } from '../actions/localStates';
import { StringToUTF16LEArray, StringToArray, toByteArray, ArrayToString, UTF16LEArrayToString, fromByteArray } from '../actions/stringEncodersFuncs';

import MessagePopup from '../components/body/MessagePopup'

import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();

class VPNKeyGeneratorContainer extends Component {

  constructor(props) {
      super(props);
      this.state = {
        uuid: null,
        fields: this.props.vpnReducer.profileFields || null,
        showPassword: false,
        vpnToken: null,
        showPopup: false,
        isLoading: false,
        showResultPassword: false
      }

      this.handleSecretChange = this.handleSecretChange.bind(this);
      this.handleOnDrop = this.handleOnDrop.bind(this);

      this.handlePwdKeyUp = this.keyUpHandler.bind(this, 'PwdInput');
      this.enterKeyUpHandler = this.enterKeyUpHandler.bind(this, 'enter');

  }

  componentDidMount(){
      var dropFile = document.getElementById('dropFile');
      document.addEventListener('keyup', this.enterKeyUpHandler);

      if (dropFile !== null) {
        dropFile.addEventListener('dragenter', function(event) {
          dropFile.style.border = "2px solid steelblue";
        }, false);

        dropFile.addEventListener('dragover', function(event) {
          dropFile.style.border = "2px solid steelblue";
        }, false);

        dropFile.addEventListener('dragleave', function(event) {
          dropFile.style.border = "";
        }, false);
      }
  }

  componentDidUpdate(prevProps) {
    var dropFile = document.getElementById('dropFile');

    if (prevProps.vpnKeyTokenSetted !== this.props.vpnReducer.vpnKeyTokenSetted) {
      if (dropFile !== null) {
        dropFile.addEventListener('dragenter', function(event) {
          dropFile.style.border = "2px solid steelblue";
        }, false);

        dropFile.addEventListener('dragover', function(event) {
          dropFile.style.border = "2px solid steelblue";
        }, false);

        dropFile.addEventListener('dragleave', function(event) {
          dropFile.style.border = "";
        }, false);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.enterKeyUpHandler);
  }

  enterKeyUpHandler(refName, e) {
    if (e.keyCode === 13 && e.target.tagName === 'BODY') {
      document.getElementById("submit").click();
      document.activeElement.blur();
    }
  }

  keyUpHandler(refName, e) {
      if (e.keyCode === 13) {
        document.getElementById("submit").click();
        document.activeElement.blur()
      }
  }

  insertDefaults () {
    var _this = this, checkStateOrProvince;
    for (var i = 0; i < this.state.fields.length; i += 1) {
      const field = this.state.fields[i]

      if (field.default !== undefined && field.default.length > 0) {
        field.default = field.default.replace(/&quot;/g, '"');
        field.value = field.default
        this.setState({field: field})
      }

      if (field.name === "COUNTRY_NAME") {
        field.value = field.default.toUpperCase()
        this.setState({field: field})
      }

      if (field.name === "DRFOU") {
        field.value = Number
        this.setState({field: field})
      }

      if (field.name === "UNZR") {
        field.value = Number
        this.setState({field: field})
      }

      if (field.name === "ORGANIZATIONAL_UNIT_NAME") {
        if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value !== null && this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value.length > 0) {
            field.value = this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value;
            field.optional = false;
            this.setState({field: field})
        }
      }

      if (!field.optional) {
        switch (field.name) {
          case "COMMON_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerFullName.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerFullName.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerFullName.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
            break;

          case "GIVEN_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerGivenname.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerGivenname.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerGivenname.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "SURNAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSurname.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSurname.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSurname.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "LOCALITY_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCity.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCity.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCity.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "STATE_OR_PROVINCE_NAME":
              // if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value.length === 0) {
              //     this.props.actions.setAllFieldsFiled(false);
              // }
          break;

          case "COUNTRY_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCountry.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCountry.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCountry.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "STREET_ADDRESS":
              // return;
              // element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress;
              // arr.push(index);
          break;

          case "EXTERNAL_USER_ID":
              // if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.customId.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.customId.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.customId.value.length === 0) {
              //     this.props.actions.setAllFieldsFiled(false);
              // }
          break;

          case "TITLE":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerPosition.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerPosition.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerPosition.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "ORGANIZATION_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganization.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganization.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganization.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "ORGANIZATIONAL_UNIT_NAME":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "POSTAL_CODE":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "DRFOU":
              if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.drfou.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.drfou.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.drfou.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "EDRPOU":
              if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.edrpou.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.edrpou.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.edrpou.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "PASSPORT":
              if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.passport.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.passport.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.passport.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "UNZR":
              if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.eddrun.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.eddrun.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.eddrun.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "SERIAL":
              if (this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSerialNumber.value === undefined || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSerialNumber.value === null || this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSerialNumber.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          case "MAIL":
              if (this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.alternativeOwnerName.value === undefined || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.alternativeOwnerName.value === null || this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.alternativeOwnerName.value.length === 0) {
                  this.props.actions.setAllFieldsFiled(false);
              }
          break;

          default:
            break;
        }
      }

    }

    checkStateOrProvince = function () {
      _this.state.fields.forEach(function(field) {
        if (field.name === "LOCALITY_NAME" && field.value.value !== null) {
          if (field.value.value !== undefined && field.value.value.length > 0) {
            if (field.value.value === "Киев" || field.value.value === "Kiev" || field.value.value === "Kyiv" || field.value.value === "Київ" || field.value.value === "Севастополь" || field.value.value === "Sevastopol" || field.value.value === "Севастополь") {
              _this.state.fields.forEach(function(field) {
                if (field.name === "STATE_OR_PROVINCE_NAME") {
                  field.optional = true;
                  _this.setState({field: field})
                }
              })
            } else {
              _this.state.fields.forEach(function(field) {
                if (field.name === "STATE_OR_PROVINCE_NAME") {
                  field.optional = false;
                  _this.setState({field: field})
                  if (field.value !== undefined && field.value.value !== null) {
                    if (field.value.value.length === 0) {
                      _this.props.actions.setAllFieldsFiled(false);
                    }
                  }
                }
              })
            }
          }
        }
      });
    }

    checkStateOrProvince();
  }

  getProfileData () {
    var _this = this, url = this.props.baseUrls.vpnKeyGeneratorUrl + "/profile";
    fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
    }).then(function(response) {
      return response.json();
    }).then(function(json) {
      console.log(json);
      _this.props.actions.setProfileFields(json.fields)
      _this.setState({"fields" : json.fields}, () => {_this.insertDefaults()})
    });
    document.activeElement.blur();
  }

  generateNewKeys () {
    // "http://193.151.165.31:9114"
    var url = this.props.baseUrls.vpnKeyGeneratorUrl, token, signature, data, dataForDS, _this = this, clientData = {};

    dataForDS = {
      agreements : [
        "Погоджуюсь з умовами регламенту приєднання до ЄІС МВС.",
        "Погоджуюсь з умовами генерації ключа електронного підпису та видачі сертифікату для доступу до ЄІС МВС.",
        "Відповідно до ст. 27 Закону України \"Про інформацію\" та статей 361-363  Кримінального Кодексу України, порушення законодавства у сфері захисту інформації тягне за собою дисциплінарну, цивільно-правову, адміністративну або кримінальну відповідальність."
      ],
      clientData
    }

    this.props.vpnReducer.profileFields.forEach(function (element) {
      if (!element.optional) {
        switch (element.name) {
          case "COMMON_NAME":
              dataForDS.clientData["COMMON_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerFullName.value;
            break;

          case "GIVEN_NAME":
              dataForDS.clientData["GIVEN_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerGivenname.value;
          break;

          case "SURNAME":
              dataForDS.clientData["SURNAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSurname.value;
          break;

          case "LOCALITY_NAME":
              dataForDS.clientData["LOCALITY_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCity.value;
          break;

          case "STATE_OR_PROVINCE_NAME":
              dataForDS.clientData["STATE_OR_PROVINCE_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value;
          break;

          case "COUNTRY_NAME":
              dataForDS.clientData["COUNTRY_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCountry.value;
          break;

          case "STREET_ADDRESS":
              // return;
              // element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress;
              // arr.push(index);
          break;

          case "EXTERNAL_USER_ID":
              // dataForDS.clientData["EXTERNAL_USER_ID"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.customId.value;
          break;

          case "TITLE":
              dataForDS.clientData["TITLE"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerPosition.value;
          break;

          case "ORGANIZATION_NAME":
              dataForDS.clientData["ORGANIZATION_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganization.value;
          break;

          case "ORGANIZATIONAL_UNIT_NAME":
              dataForDS.clientData["ORGANIZATIONAL_UNIT_NAME"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit.value;
          break;

          case "POSTAL_CODE":
              dataForDS.clientData["POSTAL_CODE"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress.value;
          break;

          case "DRFOU":
              dataForDS.clientData["DRFOU"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.drfou.value;
          break;

          case "EDRPOU":
              dataForDS.clientData["EDRPOU"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.edrpou.value;
          break;

          case "PASSPORT":
              dataForDS.clientData["PASSPORT"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.passport.value;
          break;

          case "UNZR":
              dataForDS.clientData["UNZR"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.eddrun.value;
          break;

          case "SERIAL":
              dataForDS.clientData["SERIAL"] = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSerialNumber.value;
          break;

          case "MAIL":
              dataForDS.clientData["MAIL"] = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.alternativeOwnerName.value;
          break;

          default:

          break;
        }
      }
    })

    token = this.props.vpnReducer.tokenData;

    console.log(JSON.stringify(dataForDS));

    var options = {
      signatureType: "attached",
      embedSignatureTs: "true",
      embedSignatureTsChecked: true,
      embedDataTs: "true",
      embedDataTsChecked: true,
      embedCertificateType: "signerAndCaCert",
      signatureTsVerifyOption: "ignore",
      dataTsVerifyOption: "ignore",
      tsAdditionalVerifying: false,
      dataToSignQualifier: "notSignedBefore",
      cadesType: "CAdESXLong",
      caId: this.props.pkLocalState.selectedKeyContainer
    }

    var arr
    let settedToken = {
      keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
    }
    
    arr = this.props.actions.StringToArray(JSON.stringify(dataForDS), true)

    var u8Arr = new Uint8Array(arr);

    if (this.props.pkLocalState.keyContainer !== null) {
      if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
        settedToken = {"keyStorePath": this.props.pkLocalState.keyContainer}
          this.setState({isLoading: true})
          this.props.actions.setGlobalProcessing(true)
          this.props.actions.createSession(this.props.connectionStatus.baseUrl)
              .then((response) => {
                this.setState({uuid: response.ticketUuid})
                console.log(response);
                this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, u8Arr)
                .then(() => {
                return this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, options)
                        .then(() => this.props.actions.sendTokenPath(this.props.connectionStatus.baseUrl, this.state.uuid, settedToken, null))
                        .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
                        .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
                        .then((response) => {
                            if (response !== null) {
                            var reader = new FileReader();
                              reader.onload = function () {
                                  var arrayBuffer = reader.result;
                                  var uintArray = new Uint8Array(arrayBuffer);
                                  var b64String = _this.props.actions.fromByteArray(uintArray)
                                  console.log(b64String)
                                  signature = b64String;
                              }
                              reader.readAsArrayBuffer(response);
                            }
                        })
                        .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
                        .then(() => {
                            data = {
                                "accessToken": token,
                                "qualifiedAttachedSignature" : signature,
                                "challengePassword" : this.props.vpnReducer.secret
                            }

                            this.props.actions.generateNewVPNKeys(url, data)
                            .then((response) => {
                              if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                                  this.props.actions.setResultKey(response)
                                  this.setState({isLoading: false})
                                  this.props.actions.setGlobalProcessing(false)
                                  alert(i18next.t("keygen:keysSuccessfullyGenerated"));
                              } else {
                                  if (response === null) {
                                      this.setState({isLoading: false})
                                      this.props.actions.setGlobalProcessing(false)
                                      alert("request timed out");
                                      return
                                  }
                                  this.setState({isLoading: false})
                                  this.props.actions.setErrorResponse(response.json)
                                  this.setState({showPopup: true})
                                  this.props.actions.setGlobalProcessing(false)
                                  // alert(i18next.t("keygen:keyHasNotGenerated"));
                              }
                            })
                            document.activeElement.blur();
                        })
                        // .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
              })
        })
      } else {
        this.setState({isLoading: true})
        this.props.actions.setGlobalProcessing(true)
          this.props.actions.createSession(this.props.connectionStatus.baseUrl)
              .then((response) => {
                this.setState({uuid: response.ticketUuid})
                console.log(response);
                this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, u8Arr)
                .then(() => {
                return this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, options)
                        .then(() => this.props.actions.sendKeyContainer(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainer))
                        .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
                        .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
                        .then((response) => {
                            if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                            var reader = new FileReader();
                              reader.onload = function () {
                                  var arrayBuffer = reader.result;
                                  var uintArray = new Uint8Array(arrayBuffer);
                                  var b64String = _this.props.actions.fromByteArray(uintArray)
                                  console.log(b64String)
                                  signature = b64String;
                              }
                              reader.readAsArrayBuffer(response);
                              }
                           })
                        .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
                        .then(() => {
                            data = {
                                "accessToken": token,
                                "qualifiedAttachedSignature" : signature,
                                "challengePassword" : this.props.vpnReducer.secret
                            }

                            this.props.actions.generateNewVPNKeys(url, data)
                            .then((response) => {
                              if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                                  this.props.actions.setResultKey(response)
                                  this.setState({isLoading: false})
                                  this.props.actions.setGlobalProcessing(false)
                                  alert(i18next.t("keygen:keysSuccessfullyGenerated"));
                              } else {
                                if (response === null) {
                                    this.setState({isLoading: false})
                                    this.props.actions.setGlobalProcessing(false)
                                    alert("request timed out");
                                    return
                                }
                                this.setState({isLoading: false})
                                this.props.actions.setErrorResponse(response.json)
                                this.setState({showPopup: true})
                                this.props.actions.setGlobalProcessing(false)
                               // alert(i18next.t("keygen:keyHasNotGenerated"));
                              }
                            })
                            document.activeElement.blur();
                        })
                        // .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
              })
        })
      }
    } else {
          this.setState({isLoading: true})
          this.props.actions.setGlobalProcessing(true)
          this.props.actions.createSession(this.props.connectionStatus.baseUrl)
            .then((response) => {
              this.setState({uuid: response.ticketUuid})
              console.log(response);
              this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, u8Arr)
              .then(() => {
              return this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, options)
                      .then(() => this.props.actions.sendTokenPath(this.props.connectionStatus.baseUrl, this.state.uuid, settedToken, null))
                      .then(() => this.props.actions.createDS(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
                      .then(() => this.props.actions.getDSResult(this.props.connectionStatus.baseUrl, this.state.uuid))
                      .then((response) => {
                          if (response !== null) {
                          var reader = new FileReader();
                            reader.onload = function () {
                                var arrayBuffer = reader.result;
                                var uintArray = new Uint8Array(arrayBuffer);
                                var b64String = _this.props.actions.fromByteArray(uintArray)
                                console.log(b64String)
                                signature = b64String;
                            }
                            reader.readAsArrayBuffer(response);
                          }
                      })
                      .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
                      .then(() => {
                          data = {
                              "accessToken": token,
                              "qualifiedAttachedSignature" : signature,
                              "challengePassword" : this.props.vpnReducer.secret
                          }

                          this.props.actions.generateNewVPNKeys(url, data)
                          .then((response) => {
                            if (response !== null && response.contentType !== "application/ccs.api.error+json;charset=UTF-8") {
                                this.props.actions.setResultKey(response)
                                this.setState({isLoading: false})
                                this.props.actions.setGlobalProcessing(false)
                                alert(i18next.t("keygen:keysSuccessfullyGenerated"));
                            } else {
                                if (response === null) {
                                    this.setState({isLoading: false})
                                    this.props.actions.setGlobalProcessing(false)
                                    alert("request timed out");
                                    return
                                }
                                this.setState({isLoading: false})
                                this.props.actions.setErrorResponse(response.json)
                                this.setState({showPopup: true})
                                this.props.actions.setGlobalProcessing(false)
                                // alert(i18next.t("keygen:keyHasNotGenerated"));
                            }
                          })
                          document.activeElement.blur();
                      })
                      // .then(() => this.props.createDSDefaultState.dsData ? this.getBlobData() : null)
            })
      })
    }
  }

  setShowPassword() {
    this.setState({showPassword: this.state.showPassword ? false : true})
  }

  hover() {
    this.setState({showPassword: true})
  }

  out() {
    this.setState({showPassword: false})
  }

  handleSecretChange(e) {
    if (this.props.baseUrls.globalProcessing) {
      return;
    }
    this.props.actions.setSecret(e.target.value)
  }

  showMessagePopup () {
    this.setState({
          showPopup: !this.state.showPopup
      });
  }

  showResultPassword () {
    this.setState({
          showResultPassword: !this.state.showResultPassword
      });
  }

  handleOnDrop (files) {
    if (this.props.baseUrls.globalProcessing) {
      return;
    }

    var _this = this;
    if (files[0] !== undefined && (files[0].size < 104857600)) {
      var fileReader = new FileReader(); 
      fileReader.onload = function(){ 
          _this.setState({"vpnToken": fileReader.result})
          _this.props.actions.setVPNTokenData(fileReader.result)
          console.log(fileReader.result);
      } 
        
      fileReader.readAsText(files[0]);
      this.props.actions.setVPNToken(files[0])
      console.log(files[0].name)
      
      document.activeElement.blur();
    }
  }

  downloadKey () {
      var text = this.props.vpnReducer.resultKey.keyContainerBase64Data;

      var arr = this.props.actions.toByteArray(text)
      var u8Arr = new Uint8Array(arr);

      var name = this.props.vpnReducer.vpnKeyToken.name.lastIndexOf(".") < 2 ? this.props.vpnReducer.vpnKeyToken.name : this.props.vpnReducer.vpnKeyToken.name.substr(0, this.props.vpnReducer.vpnKeyToken.name.lastIndexOf("."));

      var filename = name + ".pfx";
      function download(filename, u8Arr) {
        if (navigator.msSaveBlob) {
          var blobObject = new Blob(u8Arr);
          window.navigator.msSaveBlob(blobObject, filename);
        } else {
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          var blob = new Blob([u8Arr], {type: "octet/stream"}),
              url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
    };
    download(filename, u8Arr); 
  }

  _renderFields () {
    var arr = [], _this = this;

    this.props.vpnReducer.profileFields.forEach(function (element, index) {
      if (!element.optional) {
        switch (element.name) {
          case "COMMON_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerFullName;
              arr.push(index);
            break;

          case "GIVEN_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerGivenname;
              arr.push(index);
          break;

          case "SURNAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSurname;
              arr.push(index);
          break;

          case "LOCALITY_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCity;
              arr.push(index);
          break;

          case "STATE_OR_PROVINCE_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion;

              if (_this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value !== null && _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value.length > 0) {
                  if (_this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Киев" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Kiev" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Kyiv" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Київ" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Севастополь" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Sevastopol" || _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerRegion.value === "Севастополь") {
                    arr.push(index);
                  } else {
                    if (element.value === null) {
                      element.value.value = ""
                    }
                    arr.push(index);
                  }
              } else {
                  if (element.value.value === null) {
                    element.value.value = ""
                    arr.push(index);
                  } else {
                    if (element.value.value.length === 0) {
                      element.value.value = ""
                      arr.push(index);
                    }
                  }
              }

          break;

          case "COUNTRY_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerCountry;
              arr.push(index);
          break;

          case "STREET_ADDRESS":
              // return;
              // element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress;
              // arr.push(index);
          break;

          case "EXTERNAL_USER_ID":
              // element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.customId;
              // arr.push(index);
          break;

          case "TITLE":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerPosition;
              arr.push(index);
          break;

          case "ORGANIZATION_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganization;
              arr.push(index);
          break;

          case "ORGANIZATIONAL_UNIT_NAME":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerOrganizationUnit;
              arr.push(index);
          break;

          case "POSTAL_CODE":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerAddress;
              arr.push(index);
          break;

          case "DRFOU":
              element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.drfou;
              arr.push(index);
          break;

          case "EDRPOU":
              element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.edrpou;
              arr.push(index);
          break;

          case "PASSPORT":
              element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.passport;
              arr.push(index);
          break;

          case "UNZR":
              element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.personalData.value.eddrun;
              arr.push(index);
          break;

          case "SERIAL":
              element.value = _this.props.certificateInfo.certInfoSignature.ownerCertificateInfo.value.ownerSerialNumber;
              arr.push(index);
          break;

          case "MAIL":
              element.value = _this.props.certificateInfo.certInfoSignature.extensionsCertificateInfo.value.alternativeOwnerName;
              arr.push(index);
          break;

          default:
            arr.push(index);

            break;
        }
      }
    })

    return (
      arr.map((key, index) =>
        <div className="col-md-12" style={{marginRight: "0px"}}>
            <div className={index % 2 === 0 ? "form-group row greyStyle" : "form-group row lightStyle"} key={index}>
              <label className="col-md-4 col-form-label">
                {
                  i18next.t("keygen:" + _this.props.vpnReducer.profileFields[key].name) + ((_this.props.vpnReducer.profileFields[key].optional === true) ? ":" : "*:")
                }
              </label>

                <div className="col-md-8" style={{paddingTop: "7px"}}>
                  {
                    _this.props.vpnReducer.profileFields[key].value !== undefined
                    ? _this.props.vpnReducer.profileFields[key].value.value
                    : ""
                  }
                </div>
            </div>
        </div>)
      )
  }

  render() {
    const isEnabledAccept = this.props.vpnReducer.profileFields === null && !this.state.isLoading
    const isEnabledGeneration = this.props.vpnReducer.secret.length > 0 && !this.state.isLoading
    return (
      <div>
          <div className="offset-md-2">
              <div className="col-md-10" style={{paddingTop: "10px"}}>
                  <Dropzone ref={dropzoneRef} disabled={this.props.baseUrls.globalProcessing ? true : false} onDrop={this.handleOnDrop} multiple={false}>
                      {({getRootProps, getInputProps}) => (
                          <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone" id="dropFile">
                              <input {...getInputProps()} />
                              <p style={{cursor: "pointer", marginTop: "16px"}}>{this.props.vpnReducer.vpnKeyTokenSetted ? this.props.vpnReducer.vpnKeyToken.name : i18next.t("vpn:uploadTitle")}</p>
                          </div>
                      )}
                  </Dropzone>
              </div>
              {
                this.props.vpnReducer.vpnKeyToken !== null
                ? <div className="col-md-10">
                      <div className="card col-md-12" style={{marginTop: "10px", marginBottom: "10px"}}>
                        <div className="form-group row">
                          <label className="col-12 col-form-label" style={{paddingLeft: "15px"}}>{i18next.t("vpn:firstTerm")}</label>

                          <label className="col-12 col-form-label" style={{paddingLeft: "15px"}}>{i18next.t("vpn:secondTerm")}</label>

                          <label className="col-12 col-form-label" style={{paddingLeft: "15px"}}>{i18next.t("vpn:thirdTerm")}</label>
                      </div>

                      <div className="form-group row">
                          <button className="btn btn-default" disabled={!isEnabledAccept} onClick={this.getProfileData.bind(this)} style={{marginLeft: "15px"}}>{i18next.t("vpn:agreeBtn")}</button>
                      </div>
                    </div>
                    {
                      this.props.vpnReducer.profileFields
                      ? this._renderFields()
                      : null
                    }

                    {
                      this.props.vpnReducer.profileFields
                      ? <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-5 col-form-label" style={{marginLeft: "0px"}}>{i18next.t("vpn:secretTitle")}</label>
                              <div className="col-md-7" style={{paddingLeft: "0px", paddingRight: "0px", alignSelf: "center"}}>
                                <div className="input-group">
                                    <input className="form-control" value={this.props.vpnReducer.secret} type={this.state.showPassword ? "input" : "password"} onChange={this.handleSecretChange} onKeyUp={this.handlePwdKeyUp} />
                                    <div className="input-group-append">
                                        <div className="input-group-text" onClick={this.setShowPassword.bind(this)} onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
                                    </div>
                                </div>
                              </div>
                          </div>
                          {
                            this.props.vpnReducer.resultKey && this.props.vpnReducer.allFieldsFiled
                            ? <div className="card" style={{marginLeft: "-15px", marginRight: "-15px", paddingTop: "10px", paddingBottom: "10px", paddingRight: "15px", paddingLeft: "15px"}}>
                                <div className="row">
                                  <div className="col-md-6" style={{alignSelf: "center"}}>
                                    <button className="btn btn-default btn-block" id="submit" onClick={this.downloadKey.bind(this)} style={{marginLeft: "0px"}}>{i18next.t("vpn:downloadKeysTitle")}</button>
                                  </div>
                                  <div className="col-md-6">
                                    {
                                      this.props.vpnReducer.resultKey !== null && this.props.vpnReducer.resultKey.password !== null
                                      ? <div>
                                          {
                                            this.state.showResultPassword
                                            ? <div>
                                                <label className="col-md-12">{i18next.t("vpn:passTitle")}</label>
                                                <label className="col-md-12" style={{fontSize: "16px"}}>{this.props.vpnReducer.resultKey.password}</label>
                                              </div>
                                            : <button className="btn btn-default btn-block" id="submit" onClick={this.showResultPassword.bind(this)}>{i18next.t("vpn:showPass")}</button>
                                          }
                                        </div>
                                      : <label className="col-md-12" style={{alignSelf: "center"}}>{i18next.t("vpn:passSended")}</label>
                                    }
                                    
                                  </div>
                                  
                                </div>
                              </div>
                            : null
                          }

                          {
                            this.props.vpnReducer.allFieldsFiled
                            ? null
                            : <div className="card" style={{marginLeft: "-15px", marginRight: "-15px", paddingTop: "7px", paddingLeft: "15px", paddingRight: "15px", paddingBottom: "10px", border: "2px solid rgba(252, 98, 106, 0.5)"}}>
                                {i18next.t("vpn:fieldsFiledError")}
                              </div>
                          }
                          
                          <div className="form-group row col-md-12" style={{marginTop: "7px"}}>
                              {
                                this.props.vpnReducer.resultKey
                                ? null
                                : this.props.vpnReducer.allFieldsFiled
                                ? <button className="btn btn-default" id="submit" disabled={!isEnabledGeneration} onClick={this.generateNewKeys.bind(this)} style={{marginLeft: "0px"}}>{i18next.t("vpn:generateKeysTitle")}
                                  {
                                      this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
                                      : null
                                  }
                                  </button>
                                : null
                              }
                          </div>
                      </div>
                      : null
                    }
                  </div>
                : null
              }
          </div>
          <div>
              {
                  this.state.showPopup
                  ? <MessagePopup closeMessagePopup={this.showMessagePopup.bind(this)} data={this.props.vpnReducer.errorResponse} />
                  : null
              }
          </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        connectionStatus: state.connectionStatusReucer,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        baseUrls: state.base,
        pkLocalState: state.privateKeyReducer,
        vpnReducer: state.vpnKeyGeneratorReducer,
        certificateInfo: state.certificateInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setSecret,
        generateNewVPNKeys,
        StringToUTF16LEArray, StringToArray, toByteArray, ArrayToString, UTF16LEArrayToString,
        setVPNTokenData, clearVPNTokenData,
        setVPNToken, setProfileFields, clearProfileFields, createSession, sendInputData, sendSessionOption, sendKeyContainer, createDS,
        getDSResult, deleteSession, sendTokenPath,
        fromByteArray, setResultKey, setErrorResponse,
        setGlobalProcessing, setAllFieldsFiled
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VPNKeyGeneratorContainer);