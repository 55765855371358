import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setIsLoggedIn, setAuthLogin, setAuthPassword, setToken, setExpireTime, setTwoFactorEnabledAuth,
         clearTwoFactorEnabledAuth, setTwoFactorCodeAuth, clearTwoFactorCodeAuth, setIs2fEnabled, setTimeDelta, setPublicKeyCurrentUtcDateTime } from '../actions/localStates'
import { login, init2f, login2f, getPublicKeyCurrentUtcDateTime } from '../actions/api'
import { bindActionCreators } from 'redux'

import i18next from 'i18next'

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            numberOfPinInput: 0,
            inputs2f: 0
        }

        this.login = this.login.bind(this)
        this.login2f = this.login2f.bind(this)

        this.handleLoginChange = this.handleLoginChange.bind(this)
        this.handlePasswordChange = this.handlePasswordChange.bind(this)

        this.handlePwdKeyUp = this.keyUpHandler.bind(this, 'PwdInput');

        this.onTwoFactorCodeChanged = this.onTwoFactorCodeChanged.bind(this);

    }

    componentDidMount () {
       
    }

    onTwoFactorCodeChanged (e) {
        this.props.actions.setTwoFactorCodeAuth(e.target.value)
    }

    handleLoginChange (e) {
        this.props.actions.setAuthLogin(e.target.value);
    }

    handlePasswordChange (e) {
        this.props.actions.setAuthPassword(e.target.value);
    }

    setShowPassword() {
        this.setState({showPassword: this.state.showPassword ? false : true})
    }

    hover() {
        this.setState({showPassword: true})
    }

    out() {
        this.setState({showPassword: false})
    }

    login2f () {
        // eslint-disable-next-line
        var userData;

        var code = {
            "twoFactorCode": this.props.base.twoFactorCode
        }
        
        
        this.props.actions.login2f(this.props.base.authUrl, code, this.props.base.token)
        .then((response) => {
            if (response.code !== undefined) {
                if (response.code === "AUTHCOMMON-14") {
                    var currentCount = this.state.numberOfPinInput

                    var count = this.state.inputs2f

                    if (currentCount === 0) {
                        currentCount = this.props.base.numberOfPinInput - 1;
                    } else {
                        currentCount = currentCount - 1;
                        count += 1;
                    }

                    this.setState({inputs2f: count});

                    if (this.state.inputs2f === this.props.base.numberOfPinInput - 1) {
                        this.setState({inputs2f: 0});
                        this.setState({numberOfPinInput: 0});
                        this.props.actions.clearTwoFactorEnabledAuth();
                        this.props.actions.clearTwoFactorCodeAuth();
                        this.props.actions.setAuthPassword("");
                        alert(response.message);
                        return
                    }

                    this.setState({numberOfPinInput: currentCount});
                    this.props.actions.clearTwoFactorCodeAuth();
                    alert(response.message);
                    return

                } else {
                    this.props.actions.clearTwoFactorEnabledAuth();
                    this.props.actions.clearTwoFactorCodeAuth();
                    this.props.actions.setAuthPassword("");
                    alert(response.message);
                    return
                }
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.setState({numberOfPinInput: 0});
            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);

            this.props.actions.setExpireTime(Date.now() + (response.expires_in * 1000))

            this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.base.token)
            .then((response) => {
                var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                this.props.actions.setTimeDelta(delta)
                this.props.actions.setPublicKeyCurrentUtcDateTime(response);

                this.props.actions.setIsLoggedIn(true)
            })
        })
    }

    login () {
        var userData, user;

        user = {
            "grant_type" : "password",
            "username" : this.props.base.authLogin,
            "client_id" : this.props.base.clientId,
            "password" : this.props.base.authPassword
        }

        var data = {
            twoFactorCode: ""
        }

        this.props.actions.login(this.props.base.authUrl, user)
        .then((response) => {
            if (response.code !== undefined) {
                alert(response.message);
                return
            }

            function parseJwt (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));

                return JSON.parse(jsonPayload);
            };

            this.props.actions.setToken(response.access_token);
            userData = parseJwt(response.access_token);
            console.log(userData)

            if (userData.twof) {
                this.props.actions.init2f(this.props.base.authUrl, data, this.props.base.token)
                .then((response) => {
                    console.log(response);
                })
                this.props.actions.setTwoFactorEnabledAuth(true);
                this.props.actions.setIs2fEnabled(true);
                return;
            } else {
                if (!userData.twof) {
                    this.props.actions.login2f(this.props.base.authUrl, user, this.props.base.token)
                    .then((response) => {
                        if (response.code !== undefined) {
                            this.props.actions.clearTwoFactorEnabledAuth();
                            this.props.actions.clearTwoFactorCodeAuth();
                            this.props.actions.setPasswordInput("");
                            alert(response.message);
                            return
                        }

                        function parseJwt (token) {
                            var base64Url = token.split('.')[1];
                            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

                            return JSON.parse(jsonPayload);
                        };

                        this.props.actions.setToken(response.access_token);
                        userData = parseJwt(response.access_token);

                        this.props.actions.setExpireTime(Date.now() + (response.expires_in * 1000))

                        this.props.actions.getPublicKeyCurrentUtcDateTime(this.props.base.serviceUrl, this.props.base.token)
                        .then((response) => {
                            var delta = new Date(response.currentUtcDateTime).getTime() - Date.now()
                            this.props.actions.setTimeDelta(delta)
                            this.props.actions.setPublicKeyCurrentUtcDateTime(response);

                            this.props.actions.setIsLoggedIn(true)
                        })

                        
                    })
                }
            }
        })
    }

    cleanUpForm () {
        this.props.actions.setAuthLogin("")
        this.props.actions.setAuthPassword("")
    }

    keyUpHandler(refName, e) {
        if (e.keyCode === 13) {
            if (this.props.base.twoFactorEnabled) {
                document.getElementById("submit2f").click();
                document.activeElement.blur()
            } else {
                document.getElementById("submit").click();
                document.activeElement.blur()
            }
        }
    }

    render() {
        let enableLogin = (this.props.base.authLogin.length > 0 && this.props.base.authPassword.length > 0) ? true : false
        let enable2fLogin = this.props.base.twoFactorCode.length >= 4 ? true : false

        return (
            <div className="container">
                <div className="card offset-md-2 offset-lg-2 col-lg-8 col-md-8 col-xs-12 col-sm-12" style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    <div className="card-header">
                        {i18next.t("authTitle")}
                    </div>
                    <div className="card-body">
                        {
                            this.props.base.twoFactorEnabled
                            ? <div>
                                <input type="text" placeholder={i18next.t("oneTimeCodeTitle")} className="form-control" value={this.props.base.twoFactorCode} onChange={this.onTwoFactorCodeChanged} onKeyUp={this.handleOneTimeCodeKeyUp} ref="oneTimeCodeInput" />
                                {
                                    this.state.numberOfPinInput !== undefined && this.state.numberOfPinInput > 0
                                    ? <label style={{paddingLeft: "0px", marginBottom: "0px", paddingTop: "10px"}}>{i18next.t("triesLeft")}{this.state.numberOfPinInput}</label>
                                    : null
                                }
                                <p></p>
                                <button id="submit2f" className="btn btn-default" disabled={!enable2fLogin} onClick={this.login2f.bind(this)}>{i18next.t("authButton")}</button>
                            </div>
                            : <div>
                                <div className="form-group row">
                                    <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:loginLabel")}</label>
                                    <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                        <div className="input-group">
                                            <input type="text" className="form-control" value={this.props.base.authLogin} onChange={this.handleLoginChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-4 col-md-12 col-xs-12 col-sm-12 col-form-label">{i18next.t("pk:PasswordLabel")}</label>
                                    <div className="col-lg-8 col-md-12 col-xs-12 col-sm-12">
                                        <div className="input-group">
                                          <input className="form-control" type={this.state.showPassword ? "input" : "password"} value={this.props.base.authPassword} onChange={this.handlePasswordChange} onKeyUp={this.handlePwdKeyUp} ref="PwdInput" />
                                              <div className="input-group-append">
                                                  <div className="input-group-text" onMouseOver={this.hover.bind(this)} onMouseOut={this.out.bind(this)}><i className="fa fa-eye"></i></div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-2 order-md-6">
                                        <button id="submit" className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!enableLogin} onClick={this.login}>{i18next.t("authButton")}</button>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 order-2 order-md-6 no-left-padding">
                                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        locales: state.i18n,
        localesReducer: state.localesReducer,
        base: state.base,
        mobileModeReducer: state.mobileModeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setIsLoggedIn, setAuthLogin,
        setAuthPassword, setToken,
        login, init2f, login2f, setExpireTime, setTwoFactorEnabledAuth,
        clearTwoFactorEnabledAuth, setTwoFactorCodeAuth, clearTwoFactorCodeAuth,
        setIs2fEnabled, getPublicKeyCurrentUtcDateTime, setTimeDelta,
        setPublicKeyCurrentUtcDateTime
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);