
const dafaultState = {
    expandEncType: true,
    embedCertificateType: "nothing",
    caId: null
}

const encryptOptionsReducer = (state = dafaultState, { type, payload }) => {

    switch (type) {
    	case 'EXPAND_ENC_TYPE': {
		    return {
		        ...state,
		        expandEncType: payload
		    }
		}

        case 'SET_CERT_TYPE': {
            return {
                ...state,
                embedCertificateType: payload
            }
        }

        case 'SET_CA_ID': {
            return {
                ...state,
                caId: payload
            }
        }

        default:
            return state;
    }

}

export default encryptOptionsReducer


