
const encryptDafaultState = {
    fileForDecrypt: null,
    fileForDecryptName: "",
    fileForDecryptSelected: false,
    decrData: null,
    fileList: [],
    decryptedFiles: [],
    decryptionError: false
}

const decryptReducer = (state = encryptDafaultState, { type, payload }) => {

    switch (type) {
    	case "SET_INPUT_FILE_FOR_DECRYPT": {
		    return {
		        ...state,
		        fileForDecrypt: payload,
		        fileForDecryptName: payload.name,
		        fileForDecryptSelected: true,
		    }
		}

		case "CLEAR_INPUT_FILE_FOR_DECRYPT": {
		    return {
		        ...state,
		        fileForDecrypt: null,
		        fileForDecryptName: "",
		        fileForDecryptSelected: false,
                fileList: [],
                decryptedFiles: [],
                decryptionError: false
		    }
		}

        case 'SET_DECRYPTED_DATA': {
            return {
                ...state,
                decrData: payload
            }
        }

        case 'CLEAR_DECRYPTED_DATA': {
            return {
                ...state,
                decrData: null
            }
        }

        case "SET_DECRYPTED_FILES": {
            return {
                ...state,
                decryptedFiles: payload
            }
        }

        case "SET_FILES_FOR_DECRYPT": {
            return {
                ...state,
                fileList: payload
            }
        }

        case "SET_DECRYPTION_ERROR": {
            return {
                ...state,
                decryptionError: payload
            }
        }

        default:
            return state;
    }

}

export default decryptReducer