import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import saveAs from 'file-saver';
import { languageSwitchItem } from '../actions/locales'
import i18next from 'i18next'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { cipher, checkExtensionDecrypt } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendSessionOption, sendKeyContainer, decryptData,
			getDecryptedData, deleteSession, sendTokenPath, getDecryptorStatus, decryptVtco, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputFileForDecrypt, clearInputFileForDecrypt, setDecryptedData, clearDecryptedData, setDecryptExpand, setErrorData, setShowErrorPopup,
		 setFilesForDecrypt, setDecryptedFiles, showDecryptPreviewPopup, setDecryptionError } from '../actions/localStates'
import { bindActionCreators } from 'redux';

import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

import DecryptTextContainer from './DecryptTextContainer'
import PreviewPopup from './PreviewPopup'

import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();

class DecryptContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			isLoading: false,
			uuid: null,
			settedToken: null,
			caId: null,
			downloadIsAvailable: true,
			collapseDecryptShow: "",
			showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
      		files: this.props.defaultDecrState.fileList || [],
      		decryptedFiles: this.props.defaultDecrState.decryptedFiles || [],
			needToReEnterPin: false,
			success: 0,
      		failed: 0,
      		sizeOfFiles: 0,
			fileIndex: ""
		}


		this.withToken = this.withToken.bind(this)
		this.handleOnDrop = this.handleOnDrop.bind(this);

		this.downloadZip = this.downloadZip.bind(this);
	}

	expandDecrypt() {
		this.props.actions.setDecryptExpand(this.props.expandedCardsReducer.decryptExpanded)
		this.props.expandedCardsReducer.decryptExpanded ? this.setState({collapseDecryptShow: "card-body collapse"}) : this.setState({collapseDecryptShow: "card-body collapse show"})
	}

	buildFileForDecryptSelector(){
		const fileForDecrypt = document.getElementById('fileForDecryptUpload');
		return fileForDecrypt;
	}

	componentDidMount(){
		var dropFile = document.getElementById('dropFile');

		this.props.expandedCardsReducer.decryptExpanded ? this.setState({collapseDecryptShow: "card-body collapse show"}) : this.setState({collapseDecryptShow: "card-body collapse"})
    	this.fileForDecrypt = this.buildFileForDecryptSelector();
    	if (!this.props.pkLocalState.isActive) {
			this.cleanUpForm()
		}

		if (dropFile !== null) {
			dropFile.addEventListener('dragenter', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragover', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragleave', function(event) {
				dropFile.style.border = "";
			}, false);
		}
	}

	componentDidUpdate(prevProps) {
		var dropFile = document.getElementById('dropFile');

		if (prevProps.fileForDecryptSelected !== this.props.defaultDecrState.fileForDecryptSelected) {
			if (dropFile !== null) {
				dropFile.addEventListener('dragenter', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragover', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragleave', function(event) {
					dropFile.style.border = "";
				}, false);
			}
		}
	}

	downloadZip () {
		var _this = this
		const zip = require('jszip')();
		let files = this.props.defaultDecrState.decryptedFiles;
		for (let file = 0; file < this.props.defaultDecrState.decryptedFiles.length; file += 1) {
			var blob;
			
	    	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
			  const byteCharacters = atob(b64Data);
			  const byteArrays = [];

			  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			    const slice = byteCharacters.slice(offset, offset + sliceSize);

			    const byteNumbers = new Array(slice.length);
			    for (let i = 0; i < slice.length; i += 1) {
			      byteNumbers[i] = slice.charCodeAt(i);
			    }

			    const byteArray = new Uint8Array(byteNumbers);
			    byteArrays.push(byteArray);
			  }

			  const blob = new Blob(byteArrays, {type: contentType});
			  return blob;
			}

			var name;
			
	    	if (_this.props.base.isVtco) {
	    		blob = b64toBlob(files[file]);
	    		name = _this.props.defaultDecrState.fileList[file].name.lastIndexOf(".") < 2 ? _this.props.defaultDecrState.fileList[file].name : _this.props.defaultDecrState.fileList[file].name.substr(0, _this.props.defaultDecrState.fileList[file].name.lastIndexOf("."));
	    		zip.file(name, blob);
	    	} else {
	    		name = _this.props.defaultDecrState.fileList[file].name.lastIndexOf(".") < 2 ? _this.props.defaultDecrState.fileList[file].name : _this.props.defaultDecrState.fileList[file].name.substr(0, _this.props.defaultDecrState.fileList[file].name.lastIndexOf("."));
	    		zip.file(name, files[file]);
	    	}
		}

		zip.generateAsync({type: "blob"}).then(content => {
			saveAs(content, "CaasZippedData.zip");
		});
	}

	handleFileForDecrypt(e) {
	    e.preventDefault();
	    this.fileForDecrypt.value = null;
	    this.fileForDecrypt.click()
	    document.activeElement.blur()
	}

	// onChangeFileForDecrypt(e) {
	// 	if (e.target.files[0] !== undefined && (e.target.files[0].size < 104857601)) {
	// 		this.props.actions.setInputFileForDecrypt(e.target.files[0])
	// 		this.props.actions.clearDecryptedData()
	// 	} else {
	// 		alert(i18next.t("maxFileSizeTitle"))
	// 	}
	// }

	onChangeFileForDecrypt(e) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (e.target.files[0] !== undefined && e.target.files.length > 0) {

			if (allFilesSize < 104857601) {
				for (var i = 0; i < e.target.files.length; i += 1) {
					if (allFilesSize + (e.target.files[i].size || e.target.files[i].fileSize) < 104857601) {
						fileList.push(e.target.files[i])
						allFilesSize += e.target.files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForDecrypt(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	handleOnDrop (files) {
		let fileList = this.state.files;
		let allFilesSize = this.state.sizeOfFiles;
		let showAlert = false;
		if (fileList.length === 0) {
			this.setState({"sizeOfFiles": 0})
			allFilesSize = 0;
		}
		if (files[0] !== undefined && files.length > 0) {

			if (allFilesSize < 104857601) {
				for (var i = 0; i < files.length; i += 1) {
					if (allFilesSize + (files[i].size || files[i].fileSize) < 104857601) {
						fileList.push(files[i])
						allFilesSize += files[i].size
						this.setState({"sizeOfFiles": allFilesSize})
					} else {
						showAlert = true;
					}
				}
				this.setState({"files": fileList}, () => {
					this.props.actions.setFilesForDecrypt(this.state.files);
				})

				if (showAlert) {
					alert(i18next.t("maxFileSizeTitle"))
				}

			} else {
				alert(i18next.t("maxFileSizeTitle"))
			}
		}
	}

	deleteItem(id) {
		const files = this.state.files
		const decryptedFiles = this.state.decryptedFiles
		var allFilesSize = this.state.sizeOfFiles

		allFilesSize = allFilesSize - files[id].size
		this.setState({"sizeOfFiles": allFilesSize})

		files.splice(id, 1)
		decryptedFiles.splice(id, 1)
		this.setState({"files": files}, () => {
			this.props.actions.setFilesForDecrypt(this.state.files);
		})
		this.setState({"decryptedFiles": decryptedFiles}, () => {
			this.props.actions.setDecryptedFiles(this.state.decryptedFiles);
		})
	}

	cleanUpFileForDecrypt(){
		this.props.actions.clearInputFileForDecrypt()
		this.props.actions.clearDecryptedData()
	}

	cleanUpForm(){
		this.cleanUpFileForDecrypt()
		this.props.actions.clearDecryptedData()
		this.setState({"files": []})
		this.setState({"decryptedFiles": []})
		this.props.actions.setDecryptionError(false)
	}

	// withToken() {
	// 	var url, errorMessage;

	// 	if (this.props.pkLocalState.useUccConnection) {
	// 		url = this.props.base.urlUCC + "/api/v1"
	// 	} else {
	// 		url = this.props.base.urlCloud + "/api/v1"
	// 	}

	// 	var keyContainerPassword
	//     if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	//         const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	//         const jsEncrypt = new JSEncrypt();
	//         jsEncrypt.setPublicKey(publicKey);

	//         if (this.props.pkLocalState.useTwoFactorCode) {
	//             keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	//         } else { 
	//             keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	//         }
	//     } else {
	//         keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	//     }

	// 	this.setState({isLoading: true})
	// 	this.props.actions.createSession(url)
 //        	.then((response) => {
	// 			this.setState({uuid: response.ticketUuid})
	// 			console.log(response);
	// 		return this.props.actions.sendInputData(url, this.state.uuid, this.props.defaultDecrState.fileForDecrypt)
	// 			.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.state.caId))
	//             .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null))
 //            	.then(() => this.props.actions.decryptData(url, this.state.uuid, keyContainerPassword))
	//             .then(() => this.props.actions.getDecryptedData(url, this.state.uuid))
	//            	.then((response) => {
	//            		if (response !== null) {
 //            			this.props.actions.setDecryptedData(response)
 //            		}
 //                })
 //                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid))
 //                .then((response) => {
 //                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
 //                    	console.log(response)
 //                    	errorMessage = {
 //                          message: response.message,
 //                          techMessage: response.failureCause
 //                        }
 //                        this.props.actions.setErrorData(errorMessage)
 //                        this.props.actions.setShowErrorPopup(true)
 //                    	// alert(response.failureCause)
 //            		} else {
 //            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess"))
 //            		}
 //            		this.setState({isLoading: false})
 //                })
	//             .then(() => this.props.actions.deleteSession(url, this.state.uuid))
 //        })
	// }

	async sendFilesWithToken(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		await this.props.actions.sendInputData(url, this.state.uuid, file)
		await this.props.actions.sendSessionOption(url, this.state.uuid, this.state.caId)
        await this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null)
        await this.props.actions.decryptData(url, this.state.uuid, keyContainerPassword)
        await this.props.actions.getDecryptedData(url, this.state.uuid)
        .then((response) => {
    		if (response !== null) {
    			var encFiles = this.state.decryptedFiles
				var success = this.state.success + 1
    			encFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"decryptedFiles": encFiles}
				});
    		}
        })
        await this.props.actions.getDecryptorStatus(url, this.state.uuid)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var encFiles = this.state.decryptedFiles
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				this.props.actions.setDecryptionError(true)
				encFiles.push(response)
				this.setState(function(){
				    return {"decryptedFiles": encFiles}
				});
        	}
        })
	}

	getBlobData(id) {
	 	var blob;
    	const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
		  const byteCharacters = atob(b64Data);
		  const byteArrays = [];

		  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		    const slice = byteCharacters.slice(offset, offset + sliceSize);

		    const byteNumbers = new Array(slice.length);
		    for (let i = 0; i < slice.length; i++) {
		      byteNumbers[i] = slice.charCodeAt(i);
		    }

		    const byteArray = new Uint8Array(byteNumbers);
		    byteArrays.push(byteArray);
		  }

		  const blob = new Blob(byteArrays, {type: contentType});
		  return blob;
		}

		var fileForDownloadName = (this.props.defaultDecrState.fileList[id].name.lastIndexOf(".") || this.props.defaultDecrState.fileList[id].filename.lastIndexOf(".")) < 2 ? (this.props.defaultDecrState.fileList[id].name || this.props.defaultDecrState.fileList[id].filename) : (this.props.defaultDecrState.fileList[id].name.substr(0, this.props.defaultDecrState.fileList[id].name.lastIndexOf(".")) || this.props.defaultDecrState.fileList[id].filename.substr(0, this.props.defaultDecrState.fileList[id].filename.lastIndexOf(".")));
		
		if (fileForDownloadName.length > 0) {
	    	if (this.props.base.isVtco) {
	    		blob = b64toBlob(this.props.defaultDecrState.decryptedFiles[id]);
	    		saveAs(blob, fileForDownloadName)
	    	} else {
	    		saveAs(this.props.defaultDecrState.decryptedFiles[id], fileForDownloadName);
	    	}
	    }
	}

	showError(id) {
		var errorMessage = {
          message: this.state.decryptedFiles[id].message,
          techMessage: this.state.decryptedFiles[id].failureCause
        }
        this.props.actions.setErrorData(errorMessage)
        this.props.actions.setShowErrorPopup(true)
		// alert(this.state.encryptedFiles[id].failureCause)
	}

	selectDecryptVtco () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.decryptVtco()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			this.setState({isLoading: false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.decryptVtco2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	decryptVtco2f () {
		var data, _this = this, errorMessage;
		this.setState({isLoading: true})

		
		data = {
		    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "DECIPHERMENT",
		    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
		}

		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
		.then((response) => {
	    	if (response.code !== undefined) {
	    		if (response.code === "AUTHCOMMON-14") {
            		var currentCount = _this.state.numberOfPinInput

            		currentCount = currentCount - 1;

 					if (currentCount > 0) {
 						_this.setState({numberOfPinInput: currentCount});
						_this.setState({needToReEnterPin: true});
						_this.setState({showVtcoConfirmPopup: true})
 					} else {
 						_this.setState({numberOfPinInput: 0});
 						_this.setState({needToReEnterPin: false});
						_this.setState({showVtcoConfirmPopup: false})
						_this.setState({isLoading: false})
 					}
            	} else {
            		_this.setState({isLoading: false})
            		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)
		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
            	}
	    	} else {
	    		_this.decryptVtco();
	    	}
	    })
	}

	decryptVtco () {
		var counter = 0, resultDataArray = [], _this = this, certData, sendData, errorMessage;
		this.setState({isLoading: true})

		sendData = function () {
			var jsonObject = {
	        	pin : _this.props.pkLocalState.vtcoPin,
				dataToDecipher : resultDataArray
		    };

		    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

		    var data = {
		        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		        "encryptedData": cipherResult.encryptedData,
		        "secretKey": cipherResult.secretKey
		    }

		    _this.props.actions.decryptVtco(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
		    .then((response) => {
		    	if (response.code !== undefined) {
		    		_this.setState({isLoading: false})
		    		errorMessage = {
                      message: response.message,
                      techMessage: response.techMessage
                    }
                    _this.props.actions.setErrorData(errorMessage)
                    _this.props.actions.setShowErrorPopup(true)
		    		// var msg = response.message + "\n" + response.techMessage
		    		// alert(msg);
		    	} else {
		    		_this.setState({"decryptedFiles": response});
			    	_this.props.actions.setDecryptedFiles(response)
			    	_this.setState({isLoading: false})
		    	}
		    })
		}

		function readFile(file) {
		  return new Promise((resolve, reject) => {
		    const reader = new FileReader();

		    reader.onload = res => {
		    var base64String = window.btoa(res.target.result);
		      resolve(base64String);
		    };
		    reader.onerror = err => reject(err);

		    reader.readAsBinaryString(file);
		  });
		}

    	async function read(file) {
			resultDataArray.push(await readFile(file));
			counter += 1;

            if (counter === _this.props.defaultDecrState.fileList.length) {
            	sendData()
            }
		}

		function readFiles () {
			for (var i = 0; i < _this.props.defaultDecrState.fileList.length; i += 1) {
	            read(_this.props.defaultDecrState.fileList[i]);
			}
		}

		readFiles();
	}

	decrypt() {
		var url, _this = this, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			if (this.props.pkLocalState.isTwoFactorEnable) {
				var data = {
				    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "DECIPHERMENT",
				    "permissionTtlSeconds" : 10,
				    "permissionRepetition" : 2
				}

				this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)
			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		this.selectDecryptVtco();
			    	}
			    })
			} else {
				this.selectDecryptVtco();
			}

			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		let caId = {
	        caId: this.props.pkLocalState.selectedKeyContainer
	    }

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.setState({"caId": caId})
	            this.withToken()
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(url)
		        	.then((response) => {
						this.setState({uuid: response.ticketUuid})
						console.log(response);
					return this.props.actions.sendInputData(url, this.state.uuid, this.props.defaultDecrState.fileForDecrypt)
						.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId))
			            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer))
		            	.then(() => this.props.actions.decryptData(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword))
			            .then(() => this.props.actions.getDecryptedData(url, this.state.uuid))
			           	.then((response) => {
			           		if (response !== null) {
		            			this.props.actions.setDecryptedData(response)
		            		}
		                })
		                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid))
		                .then((response) => {
		                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
		                    	console.log(response)
		                    	errorMessage = {
			                      message: response.message,
			                      techMessage: response.failureCause
			                    }
			                    _this.props.actions.setErrorData(errorMessage)
			                    _this.props.actions.setShowErrorPopup(true)
		                    	// alert(response.failureCause)
		            		} else {
		            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(url, this.state.uuid))
		        })
		    }
		} else {
			this.setState({"settedToken": settedToken})
            this.setState({"caId": caId})
            this.withToken()
		}
    }

    async sendFiles(file) {
		var _this = this, url;
		if (!_this.props.createDSDefaultState.active) {
			return
		}

		let caId = {
	        caId: this.props.pkLocalState.selectedKeyContainer
	    }

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

    	await this.props.actions.sendInputData(url, this.state.uuid, file)
		await this.props.actions.sendSessionOption(url, this.state.uuid, caId)
        await this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer)
        await this.props.actions.decryptData(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword)
        await this.props.actions.getDecryptedData(url, this.state.uuid)
        .then((response) => {
    		if (response !== null) {
    			var decryptedFiles = this.state.decryptedFiles
				var success = this.state.success + 1
    			decryptedFiles.push(response)
    			this.setState({"success": success})
    			this.setState(function(){
				    return {"decryptedFiles": decryptedFiles}
				});
    		}
        })
        await this.props.actions.getDecryptorStatus(url, this.state.uuid)
        .then((response) => {
        	console.log(response)
        	if (response.failureCause !== undefined) {
        		var decryptedFiles = this.state.decryptedFiles
	        	var failed = this.state.failed + 1
				this.setState({"failed": failed})
				this.props.actions.setDecryptionError(true)
				decryptedFiles.push(response)
				this.setState(function(){
				    return {"decryptedFiles": decryptedFiles}
				});
        	}
        })
	}

	withToken() {
		var _this = this, url, errorMessage;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		// this.props.actions.clearEncryptedFiles()
		this.setState({"decryptedFiles": []})
		this.setState({isLoading: true})
		this.props.actions.createSession(url)
        .then((response) => {
			this.setState({uuid: response.ticketUuid})
			console.log(response);
          	
        })
        .then(async () => {
        	for (var i = 0; i < this.props.defaultDecrState.fileList.length; i += 1) {
        		await this.sendFilesWithToken(this.props.defaultDecrState.fileList[i])
			}
        })
		.then(() => this.setState({isLoading: false}))
		.then(() => this.props.actions.deleteSession(url, this.state.uuid))
		.then(() => this.props.actions.setDecryptedFiles(this.state.decryptedFiles))
		.then(() => {
			if (!_this.props.createDSDefaultState.active) {
				return
			}
			if (_this.state.failed > 0) {
				errorMessage = {
                  message: i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles")
                }
                _this.props.actions.setErrorData(errorMessage)
                _this.props.actions.setShowErrorPopup(true)
				// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles"))
			} else {
				// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles"))
			}
		})
		.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
	}

    decryptMultiple() {
		var url, _this = this, errorMessage;
		let settedToken

		if (this.props.base.isVtco) {
			if (this.props.pkLocalState.isTwoFactorEnable) {
				var data = {
				    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "DECIPHERMENT",
				    "permissionTtlSeconds" : 10,
				    "permissionRepetition" : 2
				}

				this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
			    .then((response) => {
			    	if (response.code !== undefined) {
			    		_this.setState({isLoading: false})
			    		errorMessage = {
	                      message: response.message,
	                      techMessage: response.techMessage
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)
			    		// var msg = response.message + "\n" + response.techMessage
			    		// alert(msg);
			    	} else {
			    		this.selectDecryptVtco();
			    	}
			    })
			} else {
				this.selectDecryptVtco();
			}

			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		let caId = {
	        caId: this.props.pkLocalState.selectedKeyContainer
	    }

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
	            let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.setState({"caId": caId})
	            this.withToken()
	        } else {
	        	// this.props.actions.clearEncryptedFiles()
				this.setState({"decryptedFiles": []})
				this.setState({isLoading: true})
				this.props.actions.createSession(url)
		        .then((response) => {
					this.setState({uuid: response.ticketUuid})
					console.log(response);
		          	
		        })
		        .then(async () => {
		        	for (var i = 0; i < this.state.files.length; i += 1) {
		        		await this.sendFiles(this.state.files[i])
					}
		        })
				.then(() => this.setState({isLoading: false}))
				.then(() => this.props.actions.deleteSession(url, this.state.uuid))
				.then(() => this.props.actions.setDecryptedFiles(this.state.decryptedFiles))
				.then(() => {
					if (!_this.props.createDSDefaultState.active) {
						return
					}
					if (_this.state.failed > 0) {
						errorMessage = {
	                      message: i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles")
	                    }
	                    _this.props.actions.setErrorData(errorMessage)
	                    _this.props.actions.setShowErrorPopup(true)
						// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles") + "\n" + i18next.t("enc:encCreatingError") + this.state.failed + i18next.t("enc:encFileFiles"))
					} else {
						// alert(i18next.t("enc:encSuccessfullyCreated") + this.state.success + i18next.t("enc:encFileFiles"))
					}
				})
				.then(() => this.setState({"success": 0}, () => this.setState({"failed": 0})))
	        }
	    } else {
	    	this.setState({"settedToken": settedToken})
	    	this.setState({"caId": caId})
	    	this.withToken()
	    }
	}

	showPreviewPopup (id) {
		console.log(id)
		this.setState({"fileIndex": id})
		this.props.actions.showDecryptPreviewPopup(true);
	}

	render() {
		const isEnabled = this.props.defaultDecrState.fileList.length > 0 && !this.state.isLoading
		const downloadEnable = this.props.defaultDecrState.decrData && this.state.downloadIsAvailable
		return (
            <div className="row" style={{paddingTop: "10px"}}>
				<div className="col-12">
	                <div className="card">
						<div className="card-header" onClick={this.expandDecrypt.bind(this)} role="button">
							{
								this.props.expandedCardsReducer.decryptExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
								: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							}&nbsp;{i18next.t("file")}
						</div>
	                  	<div className={this.state.collapseDecryptShow}>
	                  		<div className="row">
					            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 order-1">
					            	<ul className="add-files" style={{border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem", "listStyle": "none", "padding": "0", "minHeight": "200px"}}>
					            		{
					            			this.props.defaultDecrState.fileList.map((n, index) => {
					            				var active = index % 2 === 0 ? "evenClassName" : "oddClassName"
											    return (<li key={index} className={active} style={{"padding": "5px"}}>
											    	<span className="row">
											    		<span className={(this.state.isLoading || this.state.decryptedFiles[index]) ? "col-md-10 col-sm-10 col-xs-10" : "col-md-11  col-sm-11 col-xs-11"} style={{"wordWrap": "break-word", "display": "block"}}>{this.props.base.previewEnabled === "true" && this.props.actions.checkExtensionDecrypt(n.name) && this.state.decryptedFiles[index] &&  !this.state.decryptedFiles[index].failureCause ? <i className="fa fa-eye" style={{cursor: "pointer"}} onClick={this.showPreviewPopup.bind(this, index)}>&nbsp;</i> : null}{n.name}</span>
											    		<span className="col-md-1 col-sm-1 col-xs-1" onClick={this.deleteItem.bind(this, index)} style={{maxWidth: "30px"}}><i className="fa fa-trash" style={{"cursor": "pointer"}} title={i18next.t("deleteTitle")} aria-hidden="true"></i></span>
												    	<span className="col-md-1 col-sm-1 col-xs-1">
												    	{
												    		(this.state.decryptedFiles[index] !== undefined && this.state.decryptedFiles[index].failureCause)
												    			? <i className="fa fa-exclamation-triangle" title={i18next.t("errorTitle")} style={{"cursor": "pointer"}} onClick={this.showError.bind(this, index)}></i>
												    			: this.state.decryptedFiles[index]
												    			? <i className="fa fa-download" title={i18next.t("saveTitle")} style={{"cursor": "pointer"}} onClick={this.getBlobData.bind(this, index)}></i>
												    			: null
												    	}
												    	{
												    		this.state.isLoading && !this.state.decryptedFiles[index]
												    			? <i className="fa fa-spinner fa-pulse"></i>
												    			: null
												    	}
												    	</span>
												    </span>
											    	</li>)
											})
					            		}
					            	</ul>
					            </div>
					            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 no-left-padding order-0 order-lg-12" style={{"marginBottom": "10px"}}>
					            	{
					            		this.props.mobileModeReducer.isMobileDevice
					            		? <button className="btn btn-default btn-block" disabled={this.state.isLoading} onClick={this.handleFileInput.bind(this)}>{i18next.t("dsAddFileFilesTitle")}</button>
					            		: <Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop}>
					                        {({getRootProps, getInputProps}) => (
					                            <div {...getRootProps()} style={{cursor: "pointer"}} className="dropzone-md" id="dropFile">
					                                <input {...getInputProps()} />
					                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitle")}</p>
					                            </div>
					                        )}
					                    </Dropzone>
					            	}
					            </div>
					            <input id="fileForDecryptUpload" type="file" onChange={this.onChangeFileForDecrypt.bind(this)} style={{"display": "none"}} multiple/>
				            </div>
							<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 order-0 order-md-4">
									<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.decryptMultiple.bind(this)} disabled={!isEnabled}>{i18next.t("dec:performBinaryDataDecryption")}
										{
			                                this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
			                                : null
			                            }
									</button>
								</div>
								{/*<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 order-1 order-lg-5 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px", whiteSpace: "normal"}} disabled={!downloadEnable} onClick={this.getBlobData.bind(this)}>{i18next.t("dec:saveBinaryDecryptedData")}</button>
		                        </div>*/}
		                        <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 order-1 order-md-6 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
		                        </div>
		                        {
		                        	this.props.base.zipDownloadEnabled === "true" && this.props.defaultDecrState.decryptedFiles.length > 0 && !this.state.isLoading && this.props.defaultDecrState.decryptionError === false
		                        	? <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 order-2 order-md-6 no-left-padding">
			                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" onClick={this.downloadZip}>{i18next.t("downloadZipTitle")}</button>
			                        </div>
			                        : null
		                        }
	                        </div>
	                    </div>
	                </div>
	                <DecryptTextContainer />
	                {
						this.state.showVtcoConfirmPopup
						? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"decryptConfirm"} />
						: null
					}
					{
						this.props.base.showDecryptPreviewPopup
						? <PreviewPopup file={this.state.fileIndex} />
						: null
					}
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultDecrState: state.decryptReducer,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer,
        createDSDefaultState: state.createDSInputReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendSessionOption,
	    sendKeyContainer,
	    decryptData,
	    getDecryptedData,
	    deleteSession,
	    setInputFileForDecrypt,
	    clearInputFileForDecrypt,
	    setDecryptedData,
	    clearDecryptedData,
	    sendTokenPath,
	    getDecryptorStatus,
	    setDecryptExpand, decryptVtco,
	    cipher, premission2fVtco, premissionVtco,
	    setErrorData, setShowErrorPopup,
	    setFilesForDecrypt, setDecryptedFiles,
	    checkExtensionDecrypt, showDecryptPreviewPopup,
	    setDecryptionError
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DecryptContainer);