import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setShowErrorPopup } from '../../actions/localStates'

import i18next from 'i18next'

class ErrorPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    	showDetails: false
	    }

	    this.close = this.close.bind(this);
	}

	showDetails () {
		this.setState({showDetails: !this.state.showDetails})
	}

	componentDidMount() {

	}

	close () {
		this.props.actions.setShowErrorPopup(false)
	}

	render() {
		return (
			<div className='message-popup'>
				<div className="message-popup-inner">
					{
						this.props.base.errorData.certWarning !== undefined
						? <div>
							<div className="col-12" style={{fontWeight: "bold", paddingLeft: "30px", paddingTop: "15px", paddingRight: "30px"}}>
		                    	{
		                    		this.props.base.errorData.signatureMessage !== undefined
		                    		? <div style={{marginBottom: "5px"}}>
		                    			{i18next.t("pk:signatureTitle")}
		                    			<div className="card error-details" style={{fontWeight: "normal", paddingTop: "10px", color: "#856404", backgroundColor: "#fff3cd", paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px"}}>{this.props.base.errorData.signatureMessage}</div>
		                    		  </div>
		                    		: null
		                    	}
		                    	{
		                    		this.props.base.errorData.agreementMessage !== undefined
		                    		? <div style={{marginTop: "5px"}}>
		                    			{i18next.t("pk:keyAgreementTitle")}
		                    			<div className="card error-details" style={{fontWeight: "normal", paddingTop: "10px", color: "#856404", backgroundColor: "#fff3cd", paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px"}}>{this.props.base.errorData.agreementMessage}</div>
		                    		  </div>
		                    		: null
		                    	}
		                    </div>
						  </div>
						: <div>
		                    <div className="col-12" style={{fontWeight: "bold", paddingLeft: "30px", paddingTop: "15px", paddingRight: "30px"}}>
		                    	{this.props.base.errorData.message}
		                    	
		                    	{
		                    		this.props.base.errorData.messageSecond !== undefined ? <p>{this.props.base.errorData.messageSecond}</p> : null
		                    	}

		                    </div>
		                    {
		                    	this.props.base.errorData.techMessage !== undefined && this.props.base.errorData.techMessage.length > 0
		                    	? <div>
		                    		<div className="col-12" style={{marginBottom: "5px", marginTop: "10px", paddingLeft: "30px"}}>
				                		<label onClick={this.showDetails.bind(this)} style={{"cursor": "pointer"}}>{i18next.t("vpn:detailInfo")}&nbsp;
				                    	{
											this.state.showDetails ? <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
												: <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
										}
				                		</label>
				                	</div>
				                	<div className="col-12" style={{marginTop: "5px", paddingLeft: "30px", paddingRight: "30px"}}>
				                		{
											this.state.showDetails
											? <div className="card error-details" style={{paddingTop: "5px", color: "#856404", backgroundColor: "#fff3cd", paddingBottom: "5px", paddingLeft: "10px", paddingRight: "10px"}}>{this.props.base.errorData.techMessage}</div>
											: null
										}
				                    </div>
		                    	</div>
		                    	: null
		                    }
	                    </div>
					}
                	
                    <div className="col-12" style={{float: "right"}}>
                    	<button className="btn btn-default" style={{marginTop: "10px", marginBottom: "15px", float: "right", marginRight: "15px"}} onClick={this.close}>{i18next.t("closeLabel")}</button>
                    </div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setShowErrorPopup
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopup);