import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setSelectedMode } from '../../actions/localStates'

import i18next from 'i18next'

class JavaDownloadPopup extends Component {
	constructor(props) {
	    super(props);
	    this.state = {

	    }

	    this.redirectToJava = this.redirectToJava.bind(this)
		this.redirectToManual = this.redirectToManual.bind(this)
	}

	componentDidMount() {

	}

	redirectToManual () {
		window.open("https://docs.cipher.com.ua/x/oIPxAQ", '_blank');
	}

	redirectToJava () {
		window.open("https://java.com/ru/download/manual.jsp", '_blank');
	}

	close () {
		if (!this.props.connectionStatus.uccConnection) {
	      this.props.actions.setSelectedMode(0)
	    }
		this.props.closeJavaDownloadPopup()
	}

	goToSource (url) {
	    window.open(url, '_blank').focus();
	}

	render() {
		let mode = parseInt(this.props.pkLocalState.selectedMode, 10);
		return (
			<div className='popup'>
	        <div className='popup_inner' style={{width: "470px", height: "350px"}}>
				<div className="card">
	              	<div className="card-header">
	              	{
	              		mode === 1
          				? i18next.t("pk:pkcsDevicesActiveTitle")
          				: mode === 2
          				? i18next.t("pk:pkcsDevicesPassiveTitle")
          				: ""
	              	}
	              	
	              	</div>
	              	<div className="card-body">
          			<span>
          				{i18next.t("infoTitleJnlp")}
          			</span>
          			<span>{i18next.t("infoTitleJnlpDownload")}</span>
          			<span>{i18next.t("infoTitleJava")}</span>
          			<span><a href="https://docs.cipher.com.ua/pages/viewpage.action?pageId=78645310" target="_blank" rel="noopener noreferrer">{i18next.t("infoTitleRedirectToManual")}</a></span>

          			<p style={{"margin": "0px"}}></p>
          			<span>{i18next.t("infoTitleManualDownload")}</span>

          			{
				        this.props.base.uosAgentNativeBuilds !== undefined && this.props.base.uosAgentNativeBuilds.length > 0 && !this.props.mobileModeReducer.isMobileDevice && this.props.base.bodyIsMounted
				        ? <div style={{textAlign: "center"}}>
				            {
				              this.props.base.uosAgentNativeBuilds.map((source) => {
				                var name = ""
				                name = source.name
				                if (source.name.indexOf("windows") !== -1) {
				                  name = "windows"
				                }

				                if (source.name.indexOf("linux") !== -1) {
				                  name = "linux"
				                }

				                if (source.name.indexOf("macos") !== -1) {
				                  name = "apple"
				                }

				                return <button className="btn java-download-button" onClick={this.goToSource.bind(this, source.url)}><i className={"fa " + "fa-" + name} style={{fontSize: "1.2rem"}}></i></button>
				              })
				            }
				            
				          </div>
				        : null
				      }

				    <div style={{textAlign: "right"}}>
	                	<button className="btn btn-default" style={{width: "52px"}} onClick={this.close.bind(this)}>{i18next.t("pk:selectTitle")}</button>
	                </div>
	              </div>
	            </div>
	        </div>
	    </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        base: state.base,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        pkLocalState: state.privateKeyReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
    	setSelectedMode
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(JavaDownloadPopup);